export enum formTextFieldsName {
  firstName = 'Имя',
  middleName = 'Отчество',
  lastName = 'Фамилия',
  forGroup = 'Группа',
  department = 'Подразделение',
  post = 'Должность',
  address = 'Адрес рабочего места',
  email = 'Эл.почта',
  phone = 'Телефон',
}

export enum formTextAreasName {
  functional = 'Функционал',
  delegation = 'Делегирование полномочий',
}

export const getUpdatedData = (
  changedData: object,
  dataForWatch?: object,
  currentRowId?: string
) => {
  if (currentRowId) {
    let keysUpdatedFields = [];
    let updatedItems = {};
    Object.keys(changedData).forEach((key: string) => {
      changedData[key] &&
        changedData[key] !== dataForWatch[key] &&
        keysUpdatedFields.push(key);
    });
    if (keysUpdatedFields.length) {
      keysUpdatedFields.forEach((key: string) => {
        updatedItems[key] = changedData[key];
      });
      return updatedItems;
    }
    return;
  }
  if (!currentRowId) {
    return Boolean(
      Object.values(changedData).filter((el: string) => el !== '').length
    );
  }
};

export const convertToPatch = (data: object) => {
  let arrayForRequest = [];
  let objectForArrayRequest = {};
  const arrayFromObject = Object.entries(data).map((e) => ({ [e[0]]: e[1] }));
  arrayFromObject.forEach((el) => {
    objectForArrayRequest = {
      op: 'replace',
      path: `/${Object.keys(el)}`,
      value: `${Object.values(el)}`,
    };
    arrayForRequest.push(objectForArrayRequest);
  });
  const departmentField = arrayForRequest.find(
    (el) => el.path === '/department'
  );
  departmentField?.value === '' && (departmentField.value = null);
  return arrayForRequest;
};

export const convertToFiltering = (value: string) => {
  // let arrayForRequest = []
  // Object.keys(formValues).forEach((el, index) => {
  // 	const objectForArrayRequest = new Object({
  // 		"logicalOperator": index === 0 ? "" : "or",
  // 		"field": `${el}`,
  // 		"operator": "like",
  // 		"value": `${value}`
  // 	})
  // 	arrayForRequest.push(objectForArrayRequest)
  // })
  // return arrayForRequest
};
