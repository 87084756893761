import { Checkbox } from "@mui/material"
import AutoComplete, { AutoCompleteProps } from "components/ui/auto-complete/AutoComplete"
import FontIcon from "components/ui/FontIcon"
import renderOption from "components/utils/render-option"
import { EmployeeType } from "models/employee.model"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useGetFilteredEmployeeMutation } from "reducers/api/employee.api"

const EmployeeAutoComplete = ({ multiple, ...rest }: AutoCompleteProps<EmployeeType>) => {

  const { t } = useTranslation()

  const [getFilteredEmployee, { data: employeeData, isLoading: isEmployeeLoading }] = useGetFilteredEmployeeMutation()

  useEffect(() => {
    getFilteredEmployee([])
  }, [])

  return (
    <AutoComplete<EmployeeType>
      autoFocus
      openOnFocus
      disableClearable
      size='small'
      clientSide
      data={employeeData?.content}
      loading={isEmployeeLoading}
      optionLabel='name'
      getOptionKey={(option: EmployeeType) => option.id}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      renderInputProps={{
        label: t('kbl.labels.chooseEmployee')
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && (
            <Checkbox
              icon={<FontIcon icon='check_box_outline_blank' sx={{ color: 'primary.text03' }} />}
              checkedIcon={<FontIcon icon='check_box' sx={{ color: 'primary.main' }} />}
              checked={selected}
              sx={{
                padding: 0
              }}
            />
          )}
          {renderOption({ label: option?.shortName, description: option?.email })}
        </li>
      )}
      {...rest}
    />
  )
}

export default EmployeeAutoComplete
