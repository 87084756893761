import { Stack } from '@mui/material'
import NodeType from 'models/node.model'
import ResponsibleDialog from "modules/Main/Content/ResponsibleReference/ResponsibleDialog"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useConfirm from "../../../../hooks/useConfirm"
import { ResponsibleType } from "../../../../models/responsible.model"
import {
	useGetFilteredResponsibleMutation,
	useRemoveResponsibleMutation,
} from "../../../../reducers/api/responsible.api"
import QuickNodes from '../TopPanel/QuickNodes/QuickNodes'
import ResponsibleInfoDialog from './ResponsibleInfoDialog'
import ResponsibleNodeDialog from "./ResponsibleNodeDialog/ResponsibleNodeDialog"
import ResponsibleReferenceTable from "./ResponsibleReferenceTable/ResponsibleReferenceTable"

const ResponsibleReference = ({ node }: { node: NodeType }) => {

	const [selectedResponsible, setSelectedResponsible] = useState<ResponsibleType>(null)
	const [openResponsibleDialog, setOpenResponsibleDialog] = useState(false)
	const [openResponsibleNodeDialog, setOpenResponsibleNodeDialog] = useState(false)
	const [openResponsibleInfoDialog, setOpenResponsibleInfoDialog] = useState(false)

	const { t } = useTranslation()
	const [queryOptions, setQueryOptions] = useState([])
	const confirmArchive = useConfirm()
	const [removeResponsible] = useRemoveResponsibleMutation()
	const [getFilteredResponsible, { data: responsibleData }] = useGetFilteredResponsibleMutation()

	useEffect(() => {
		getFilteredResponsible(queryOptions)
	}, [queryOptions])

	const handleAddResponsible = () => {
		setOpenResponsibleDialog(true)
	}

	const closeResponsibleDialog = () => {
		setOpenResponsibleDialog(false)
		setSelectedResponsible(null)
	}

	const closeResponsibleInfoDialog = () => {
		setOpenResponsibleInfoDialog(false)
		setSelectedResponsible(null)
	}

	const closeResponsibleNodeDialog = () => {
		setOpenResponsibleNodeDialog(false)
		setSelectedResponsible(null)
	}

	const showResponsibleInfo = useCallback((data: ResponsibleType) => {
		setSelectedResponsible(data)
		setOpenResponsibleInfoDialog(true)
	}, [])

	const showResponsibleNode = useCallback((data: ResponsibleType) => {
		setSelectedResponsible(data)
		setOpenResponsibleNodeDialog(true)
	}, [])

	const updateResponsible = useCallback((data: ResponsibleType) => {
		setSelectedResponsible(data)
		setOpenResponsibleDialog(true)
	}, [])

	const deleteResponsible = useCallback((data: ResponsibleType) => {
		confirmArchive({
			title: t('kbl.dialog.title.general.confirm'),
			message: t('kbl.dialog.message.deleteResponsible'),
			acceptColor: 'error',
			onAccept: async () => {
				await removeResponsible({ id: data.id })
				getFilteredResponsible(queryOptions)
			},
		})
	}, [])

	const handleSuccess = () => {
		getFilteredResponsible(queryOptions)
	}

	return (
		<Stack overflow='auto'>
			<QuickNodes parent={node} />

			<ResponsibleReferenceTable
				data={responsibleData?.content}
				onResponsibleClick={showResponsibleInfo}
				handleAddResponsible={handleAddResponsible}
				showResponsibleNode={showResponsibleNode}
				updateResponsible={updateResponsible}
				deleteResponsible={deleteResponsible}
				setQueryOptions={setQueryOptions}
			/>

			{openResponsibleDialog && (
				<ResponsibleDialog
					responsible={selectedResponsible}
					onClose={closeResponsibleDialog}
					onSuccess={handleSuccess}
				/>
			)}

			{openResponsibleInfoDialog && (
				<ResponsibleInfoDialog
					responsible={selectedResponsible}
					onClose={closeResponsibleInfoDialog}
				/>
			)}

			{openResponsibleNodeDialog && (
				<ResponsibleNodeDialog
					responsible={selectedResponsible}
					onClose={closeResponsibleNodeDialog}
				/>
			)}
		</Stack>
	)
}

export default ResponsibleReference
