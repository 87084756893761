import { Stack, Typography } from "@mui/material"
import Loading from "components/Loading/Loading"
import Button from "components/ui/Button/Button"
import { ResponsibleType } from "models/responsible.model"
import { useTranslation } from "react-i18next"
import Dialog from "../../../../../components/ui/Dialog"
import {
	useGetResponsibleQuery
} from "../../../../../reducers/api/responsible.api"
import { formTextAreasName } from '../ResponsibleDialog/utils'

const Info = ({ label, value }: { label: string; value: string }) => (
  <Stack>
    <Typography variant='subtitle1' sx={{ opacity: '0.5' }}>
      {label}
    </Typography>
    <Typography sx={{ userSelect: 'text', whiteSpace: 'pre-line' }}>
      {value}
    </Typography>
  </Stack>
);

interface ResponsibleInfoDialogProps {
	responsible?: ResponsibleType
	onClose: () => void
}

const ResponsibleInfoDialog = ({ responsible, onClose }: ResponsibleInfoDialogProps) => {

	const { t } = useTranslation()

	const { data: responsibleData, isLoading } = useGetResponsibleQuery({ id: responsible?.id }, { skip: !responsible?.id })

	return (
    <Dialog
      fullHeight
      open={true}
      title={t('kbl.dialog.title.general.responsibleInfo')}
      onClose={onClose}
      actions={
        <Button fullWidth variant='outlined' onClick={onClose}>
          {t('kbl.buttons.close')}
        </Button>
      }
    >
      <Loading showLoading={isLoading}>
        <Stack spacing='8px' paddingBottom='20px'>
          <Info
            label={t('kbl.dataGrid.fullName')}
            value={responsibleData?.fullName}
          />
          <Info
            label={t('kbl.dataGrid.group')}
            value={responsibleData?.forGroup}
          />
          <Info
            label={t('kbl.dataGrid.department')}
            value={responsibleData?.department}
          />
          <Info label={t('kbl.dataGrid.post')} value={responsibleData?.post} />
          <Info
            label={t('kbl.dataGrid.address')}
            value={responsibleData?.address}
          />
          <Info
            label={t('kbl.dataGrid.functional')}
            value={responsibleData?.functional}
          />
          <Info
            label={t('kbl.dataGrid.email')}
            value={responsibleData?.email}
          />
          <Info
            label={t('kbl.dataGrid.phone')}
            value={responsibleData?.phone}
          />
          <Info
            label={formTextAreasName.delegation}
            value={responsibleData?.delegation}
          />
        </Stack>
      </Loading>
    </Dialog>
  );
}

export default ResponsibleInfoDialog
