import { Stack, TextField } from "@mui/material"
import { ResponsibleType } from "models/responsible.model"
import { useFormContext } from "react-hook-form"
import { getArrayTypeKeys, getArrayTypeValues } from "../../ResponsibleNodeDialog/utils"
import { formTextAreasName, formTextFieldsName } from '../utils'

const ResponsibleForm = (props) => {

	const { register } = useFormContext<ResponsibleType>()

	return (
    <form id='responsible-form' autoComplete='off'>
      <Stack spacing='16px'>
        {getArrayTypeKeys(formTextFieldsName).map((el, i) => (
          <TextField
            key={i}
            {...register(
              el,
              el === 'firstName' || el === 'middleName' || el === 'lastName'
                ? { required: true }
                : { required: false }
            )}
            size='medium'
            variant='outlined'
            label={`${getArrayTypeValues(formTextFieldsName)[i]} *`}
            fullWidth
          />
        ))}
        {getArrayTypeKeys(formTextAreasName).map((el, i) => (
          <TextField
            key={i}
            variant='outlined'
            fullWidth
            multiline
            rows={3}
            label={`${getArrayTypeValues(formTextAreasName)[i]} *`}
            sx={{
              '& .MuiOutlinedInput-root': { height: '88px' },
            }}
          />
        ))}
      </Stack>
    </form>
  );
}

export default ResponsibleForm
